import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {Mutex} from 'async-mutex';
import {useBootstrap, useEntities, useEntity, useProgress} from "@atttomyx/shared-hooks";
import {usePushEvent} from "@atttomyx/react-hooks";
import {
    AboutPage,
    NotFoundPage,
    PhoneStatusBar,
    ProfilePage,
    PublicHeader,
    Splash,
    StorePage,
    UserSettingsPage
} from "@atttomyx/react-components";
import {
    ChooseUsernamePage,
    constants as games,
    CreateChallengePage,
    DashboardPage,
    GamePage,
    games as gameUtils,
    LeaderBoardsPage
} from "@atttomyx/react-games";
import {Typography} from "@mui/material";
import UserSettingsForm from "./forms/userSettingsForm/userSettingsForm";
import BackToHome from "./components/backToHome/backToHome";
import Sudoku from "./components/sudoku/sudoku";
import * as appService from "./services/app";
import * as authService from "./services/auth";
import * as myAccountService from "./services/noopMyAccount";
import * as userService from "./services/users";
import * as profileService from "./services/profile";
import * as notificationService from "./services/notifications";
import * as gameService from "./services/game";
import * as challengeService from "./services/challenges";
import * as mobileService from "./services/mobile";
import * as cacheService from "./services/cache";
import {firebase as firebaseUtils, iaps as iapUtils, mobile, router} from "@atttomyx/react-utils";
import {errors, notifications, strings, users} from "@atttomyx/shared-utils";
import {sortByMistakesAndElapsed} from "./utils/sorting";
import {getBest, toScore, toSub} from "./utils/stats";
import {toJoinUrl} from "./utils/deepLinks";
import {settingsToBranding} from "./utils/users";
import {themes, time} from "@atttomyx/shared-constants";
import {
    APP_ID_APPLE,
    APP_ID_GOOGLE,
    APP_NAME,
    APP_TAG_LINE,
    DEFAULT_BEST,
    EXPLANATION_EASY,
    EXPLANATION_HARD,
    EXPLANATION_MEDIUM,
    FIREBASE_CONFIG,
    IAP_ANDROID_GEMS_1,
    IAP_ANDROID_GEMS_2,
    IAP_ANDROID_GEMS_3,
    IAP_ANDROID_GEMS_4,
    IAP_ANDROID_GEMS_5,
    IAP_ANDROID_GEMS_6,
    IAP_ANDROID_NO_BANNER_ADS,
    IAP_IOS_GEMS_1,
    IAP_IOS_GEMS_2,
    IAP_IOS_GEMS_3,
    IAP_IOS_GEMS_4,
    IAP_IOS_GEMS_5,
    IAP_IOS_GEMS_6,
    IAP_IOS_NO_BANNER_ADS,
    PAGE_ABOUT,
    PAGE_CHALLENGE,
    PAGE_DASHBOARD,
    PAGE_GAME,
    PAGE_HOME,
    PAGE_LEADERBOARDS,
    PAGE_OPTIONS,
    PAGE_PROFILE,
    PAGE_STORE,
    PAGE_USERNAME,
    PAGES_EXACT,
    PAGES_STARTS_WITH,
} from "./constants";
import icon from "./icon.png";
import noBannerAds from "./images/noBannerAds.png";
import gems1 from "./images/1_pouchOfGems.png";
import gems2 from "./images/2_bowlOfGems.png";
import gems3 from "./images/3_bucketOfGems.png";
import gems4 from "./images/4_crateOfGems.png";
import gems5 from "./images/5_vaultOfGems.png";
import gems6 from "./images/6_boatloadOfGems.png";

const MOCK_PHONE = false;
const MAX_ATTEMPTS = 3;
const loginMutex = new Mutex();

let loginAttempts = 0;
let loggingIn = false;

const App = (props) => {
    const {snackbar, dimensions, history, onThemeChange} = props;
    const stats = useEntity(gameService.loadStats, snackbar.setError);
    const leaderBoards = useEntity(gameService.loadLeaderBoards, snackbar.setError);
    const challenges = useEntities(challengeService.loadChallenges, snackbar.setError, "challenges");
    const [iaps, setIaps] = useState(iapUtils.sanitizeIaps(null));
    const [allowAnonymous, setAllowAnonymous] = useState(false);
    const [joinId, setJoinId] = useState(null);

    const onLogout = () => {
        authService.cancelAllRequests("logged out");
        authService.clearAuthToken();

        progress.clear();
        bootstrap.clear();
        stats.clear();
        leaderBoards.clear();
        challenges.clear();
    };

    const bootstrapSuccess = () => {
        if (!allowAnonymous && users.isAnonymous(bootstrap.user)) {
            router.redirectTo(history, PAGE_USERNAME);

        } else {
            router.redirectToInitialPage(history, PAGES_EXACT, PAGES_STARTS_WITH, PAGE_HOME);
        }

        stats.refresh();
        leaderBoards.refresh();
        challenges.refresh();

        if (mobile.isMobile()) {
            mobile.lifecycle(true, true);
        }

        loginAttempts = 0;
    };

    const bootstrap = useBootstrap(authService, myAccountService, profileService, notificationService,
        bootstrapSuccess, errors.log);

    const progress = useProgress(bootstrap,
        [challenges],
        [stats, leaderBoards],
        0,
        time.MILLISECONDS_IN_SECOND);

    const event = usePushEvent(bootstrap);

    const onLogin = (accounts) => {
        progress.refresh();
        bootstrap.refresh(accounts);
    };

    const resetLogin = () => {
        if (!loggingIn) {
            loginMutex.acquire()
            .then(function (release) {
                try {
                    if (!loggingIn) {
                        loggingIn = true;
                        attemptLogin();
                    }
                } finally {
                    release();
                }
            }.bind(this));
        }
    };

    const attemptLogin = () => {
        if (mobile.isLocalhost() || mobile.isMobile()) {
            const installationId = mobile.initInstallationId();
            // const installationId = newInstallationId();
            const attempt = ++loginAttempts;

            const success = (accounts) => {
                loggingIn = false;
                onLogin(accounts);
            };

            const failure = (err) => {
                errors.log(err);

                if (attempt > MAX_ATTEMPTS) {
                    loggingIn = false;
                    onLogout();

                } else {
                    attemptLogin();
                }
            };

            console.log(`-- login attempt ${attempt} --`);
            authService.simpleLogin(installationId, success, failure);

        } else {
            onLogout();
        }
    };

    const redirectToChallenge = (challenge) => {
        router.redirectTo(history, `${PAGE_GAME}/${challenge.id}`);
    };

    useEffect(() => {
        if (event && event.type) {
            const type = event.type;
            const data = event.payload || {};
            const payload = data.payload || {};
            const me = bootstrap.user && bootstrap.user.id === payload.userId;

            console.table(type, data);

            switch (type) {
                case games.CHALLENGE_SENT:
                case games.CHALLENGE_DECLINED:
                case games.CHALLENGE_COMPLETED:
                case games.TOP_TEN_EASY:
                case games.TOP_TEN_MEDIUM:
                case games.TOP_TEN_HARD:
                    const challengeId = payload.challengeId;

                    if (challengeId && !me) {
                        const success = (challenge) => challenges.onEntitySaved(challenge);
                        const failure = (err) => snackbar.setError(err);

                        challengeService.loadChallenge(challengeId, success, failure);
                    }
                    break;
                case games.RESET_GLOBAL_CHALLENGES:
                    challenges.refresh();
                    break;
                case games.UPDATED_LEADER_BOARDS:
                    leaderBoards.refresh();
                    break;
                default:
                    break;
            }

            if (notifications.isEnabled(bootstrap.user, type) || notifications.isSubscribed(bootstrap.user, type)) {
                const title = data.title;

                if (strings.isNotBlank(title) && !me) {
                    snackbar.setInfo(title);
                }
            }
        }
    }, [event]);

    useEffect(() => {
        const theme = bootstrap.user ? bootstrap.user.settings.theme || themes.LIGHT : themes.LIGHT;
        const branding = bootstrap.user
            ? settingsToBranding(bootstrap.user.settings)
            : gameUtils.defaultBranding(theme);

        if (mobile.isMobile()) {
            mobile.setBranding(theme, branding);

            if (bootstrap.user && bootstrap.user.settings.ads !== games.ADS_NONE) {
                mobile.showBannerAd();

            } else {
                mobile.hideBannerAd();
            }
        }

        onThemeChange(theme, branding);
    }, [bootstrap.user]);

    useEffect(() => {
        if (mobile.isMobile()) {
            mobile.syncProgress(progress.percent, progress.loading);
        }
    }, [progress.percent, progress.loading]);

    useEffect(() => {
        if (joinId && challenges.entities.length > 0) {
            const challenge = gameUtils.findChallenge(challenges, joinId);

            if (challenge) {
                redirectToChallenge(challenge);

            } else if (!gameUtils.isDifficulty(joinId)) {
                const success = (challenge) => {
                    challenges.onEntitySaved(challenge);
                    redirectToChallenge(challenge);
                };

                const failure = (err) => snackbar.setError("Unable to join challenge");

                challengeService.joinChallenge(joinId, success, failure);

            } else {
                snackbar.setError("Unable to join challenge");
            }

            setJoinId(null);
        }
    }, [challenges.entities, joinId]);

    useEffect(() => {
        if (mobile.isMobile()) {
            mobileService.initMessageObservers(snackbar, history, bootstrap, setJoinId, iaps, setIaps);
        }
    }, [snackbar, history, bootstrap, iaps]);

    useEffect(() => {
        appService.ensureOnLatestVersion(false, null);
        authService.setupAxiosInterceptors(resetLogin);
        notifications.initNotifications(games.NOTIFICATION_TYPES, games.TOPICS);
        firebaseUtils.initFirebase(FIREBASE_CONFIG);
        dimensions.onResize();

        if (mobile.isMobile()) {
            mobile.initializeMessageListener();
        }

        if (authService.loggedIn()) {
            onLogin();

        } else {
            attemptLogin();
        }

        return () => {
            if (mobile.isMobile()) {
                mobile.deactivateMessageListener();
            }
        }
    }, []);

    const renderNotMobile = () => {
        return <AboutPage
            src={icon}
            appName={APP_NAME}
            appDescription={APP_TAG_LINE}
            appIdApple={APP_ID_APPLE}
            appIdGoogle={APP_ID_GOOGLE}
            appService={appService}
        />
    };

    const renderLoading = () => {
        return <Splash
            percent={progress.percent}
        />
    };

    const renderFailureToLogin = () => {
        return <>
            <PublicHeader
                dimensions={dimensions}
                appName={APP_NAME}
                src={icon}
            />
            <div className="content">
                <Typography variant="h5">
                    Unable to login
                </Typography>
                <Typography variant="caption">
                    Please close the app and re-open to try again
                </Typography>
            </div>
        </>
    };

    const renderAnonymous = () => {
        return <>
            <PublicHeader
                dimensions={dimensions}
                appName={APP_NAME}
                src={icon}
            />
            <div className="content">
                <Switch>
                    <Route exact path="/">
                        <Redirect to={PAGE_USERNAME}/>
                    </Route>
                    <Route path={PAGE_USERNAME}>
                        <ChooseUsernamePage
                            snackbar={snackbar}
                            user={bootstrap.user}
                            onLogin={() => {
                                onLogin();
                                router.redirectTo(history, PAGE_PROFILE);
                            }}
                            onSaveProfile={bootstrap.syncProfile}
                            onChosen={() => router.redirectTo(history, PAGE_PROFILE)}
                            onAnonymous={() => {
                                setAllowAnonymous(true);
                                router.redirectTo(history, PAGE_DASHBOARD);
                            }}
                            authService={authService}
                            profileService={profileService}
                            appName={APP_NAME}
                        />
                    </Route>
                    <Route>
                        <NotFoundPage/>
                        <BackToHome home={PAGE_USERNAME}/>
                    </Route>
                </Switch>
            </div>
        </>
    };

    const renderAuthenticated = () => {
        return <>
            {MOCK_PHONE ? <PhoneStatusBar/> : null}
            <div className="content">
                <Switch>
                    <Route exact path="/">
                        <Redirect to={PAGE_HOME}/>
                    </Route>
                    <Route path={PAGE_DASHBOARD}>
                        <DashboardPage
                            snackbar={snackbar}
                            user={bootstrap.user}
                            leaderBoards={leaderBoards.entity}
                            stats={stats.entity}
                            challenges={challenges.entities}
                            onChallengeCreated={challenges.onEntitySaved}
                            onChallengesDeleted={challenges.onEntitiesDeleted}
                            onGotoProfile={() => router.redirectTo(history, PAGE_PROFILE)}
                            onGotoOptions={() => router.redirectTo(history, PAGE_OPTIONS)}
                            onGotoLeaderBoards={() => router.redirectTo(history, PAGE_LEADERBOARDS)}
                            onGotoNewGame={() => router.redirectTo(history, PAGE_GAME)}
                            onGotoNewChallenge={() => router.redirectTo(history, PAGE_CHALLENGE)}
                            onGotoChallenge={(challengeId) => router.redirectTo(history,
                                `${PAGE_GAME}/${challengeId}`)}
                            onGotoResumeGame={() => router.redirectTo(history,
                                `${PAGE_GAME}/${games.RESUME}`)}
                            onGotoAbout={() => router.redirectTo(history, PAGE_ABOUT)}
                            onGotoStore={() => router.redirectTo(history, PAGE_STORE)}
                            toJoinUrl={toJoinUrl}
                            toScore={toScore}
                            sorter={sortByMistakesAndElapsed}
                            cacheService={cacheService}
                            challengeService={challengeService}
                        />
                    </Route>
                    <Route path={PAGE_LEADERBOARDS}>
                        <LeaderBoardsPage
                            snackbar={snackbar}
                            user={bootstrap.user}
                            leaderBoards={leaderBoards.entity}
                            scoreLabel="Time"
                            toScore={toScore}
                            subLabel="Mistakes"
                            toSub={toSub}
                            sorter={sortByMistakesAndElapsed}
                            cacheService={cacheService}
                        />
                        <BackToHome/>
                    </Route>
                    <Route path={PAGE_CHALLENGE}>
                        <CreateChallengePage
                            snackbar={snackbar}
                            user={bootstrap.user}
                            onChallengeCreated={challenges.onEntitySaved}
                            onGotoDashboard={() => router.redirectTo(history, PAGE_DASHBOARD)}
                            easy={EXPLANATION_EASY}
                            medium={EXPLANATION_MEDIUM}
                            hard={EXPLANATION_HARD}
                            toJoinUrl={toJoinUrl}
                            toScore={toScore}
                            cacheService={cacheService}
                            challengeService={challengeService}
                            userService={userService}
                        />
                    </Route>
                    <Route exact path={PAGE_GAME}>
                        <GamePage
                            snackbar={snackbar}
                            user={bootstrap.user}
                            stats={stats.entity}
                            onGotoDashboard={() => router.redirectTo(history, PAGE_DASHBOARD)}
                            onGotoCreateChallenge={() => router.redirectTo(history, PAGE_CHALLENGE)}
                            onDone={stats.refresh}
                            easy={EXPLANATION_EASY}
                            medium={EXPLANATION_MEDIUM}
                            hard={EXPLANATION_HARD}
                            best={DEFAULT_BEST}
                            toBest={getBest}
                            difficulty={bootstrap.user.settings.difficulty}
                            cacheService={cacheService}
                            gameService={gameService}
                            board={Sudoku}
                        />
                    </Route>
                    <Route
                        path={PAGE_GAME + "/:id"}
                        render={props => <GamePage
                            {...props}
                            snackbar={snackbar}
                            user={bootstrap.user}
                            stats={stats.entity}
                            challenges={challenges.entities}
                            onGotoDashboard={() => router.redirectTo(history, PAGE_DASHBOARD)}
                            onGotoCreateChallenge={() => router.redirectTo(history, PAGE_CHALLENGE)}
                            onDone={stats.refresh}
                            onChallengeCreated={challenges.onEntitySaved}
                            onResultSaved={(challengeId, result) =>
                                gameUtils.resultSaved(bootstrap.user, challenges, challengeId, result,
                                    sortByMistakesAndElapsed)}
                            easy={EXPLANATION_EASY}
                            medium={EXPLANATION_MEDIUM}
                            hard={EXPLANATION_HARD}
                            best={DEFAULT_BEST}
                            toBest={getBest}
                            difficulty={bootstrap.user.settings.difficulty}
                            cacheService={cacheService}
                            challengeService={challengeService}
                            gameService={gameService}
                            board={Sudoku}
                        />}
                    />
                    <Route path={PAGE_PROFILE}>
                        <ProfilePage
                            snackbar={snackbar}
                            user={bootstrap.user}
                            onSave={bootstrap.syncProfile}
                            profileService={profileService}
                            showImage={true}
                            showId={true}
                            useAlias={true}
                        />
                        <BackToHome/>
                    </Route>
                    <Route path={PAGE_OPTIONS}>
                        <UserSettingsPage
                            snackbar={snackbar}
                            user={bootstrap.user}
                            onSaveProfile={bootstrap.syncProfile}
                            onSaveNotifications={bootstrap.syncNotifications}
                            profileService={profileService}
                            notificationService={notificationService}
                            settingsLabel="Look and Feel"
                            settingsForm={UserSettingsForm}
                            mobileOnly={true}
                        />
                        <BackToHome/>
                    </Route>
                    <Route path={PAGE_ABOUT}>
                        <AboutPage
                            src={icon}
                            appName={APP_NAME}
                            appDescription={APP_TAG_LINE}
                            appService={appService}
                        />
                        <BackToHome/>
                    </Route>
                    <Route path={PAGE_STORE}>
                        <StorePage
                            snackbar={snackbar}
                            onRestore={(ownedIds, success, failure) =>
                                mobileService.restorePurchases(bootstrap, ownedIds, success, failure)}
                            iaps={iaps}
                            images={{
                                [IAP_IOS_NO_BANNER_ADS]: noBannerAds,
                                [IAP_IOS_GEMS_1]: gems1,
                                [IAP_IOS_GEMS_2]: gems2,
                                [IAP_IOS_GEMS_3]: gems3,
                                [IAP_IOS_GEMS_4]: gems4,
                                [IAP_IOS_GEMS_5]: gems5,
                                [IAP_IOS_GEMS_6]: gems6,
                                [IAP_ANDROID_NO_BANNER_ADS]: noBannerAds,
                                [IAP_ANDROID_GEMS_1]: gems1,
                                [IAP_ANDROID_GEMS_2]: gems2,
                                [IAP_ANDROID_GEMS_3]: gems3,
                                [IAP_ANDROID_GEMS_4]: gems4,
                                [IAP_ANDROID_GEMS_5]: gems5,
                                [IAP_ANDROID_GEMS_6]: gems6,
                            }}
                        />
                        <BackToHome/>
                    </Route>
                    <Route>
                        <NotFoundPage/>
                        <BackToHome/>
                    </Route>
                </Switch>
            </div>
        </>
    };

    return <div className="app">
        {!mobile.isMobile() && !mobile.isLocalhost()
            ? renderNotMobile()
            : loginAttempts > MAX_ATTEMPTS
                ? renderFailureToLogin()
                : progress.loading || progress.paused || !bootstrap.user
                    ? renderLoading()
                    : !allowAnonymous && users.isAnonymous(bootstrap.user)
                        ? renderAnonymous()
                        : renderAuthenticated()}
    </div>
}

export default withRouter(App);
